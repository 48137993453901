.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 20px;
}

.App-link {
  color: #61dafb;
}

.weekRow {
  width: 100%;
}

.weekButton {
  padding-left: 2px;
  padding-right: 2px;
}

.weekButtonPassed {
  padding-left: 2px;
  padding-right: 2px;
  background: #dddddd;
}

.seePicksButton {
  margin-top: 5px;
}

.weekCol {
  margin-right: 16px;
}

.main-container {
  margin-bottom: 100px;
  margin-top: 20px;
}

.recordsList {
  width: fit-content;
  text-align: left;
}

.align-left {
  text-align: left;
}

.me-auto {
  margin-right: auto !important;
}
.gameCard {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .gamesRow {
    justify-content: center;
  }
}

.gamesRow {
}

.cardLocked {
  background: #dddddd;
}

.card-body {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.card {
  height: 100%;
}
.logOutButton {
  margin-left: 20px;
}
.scoreBox {
  text-align: center;
  border: 2px solid black;
}

.logo {
  height: 50px;
  width: 50px;
}

.pickButton {
  margin-left: 3px;
  margin-right: 3px;
}

.pickButtons {
  display: flex;
  justify-content: center;
}

.deleteButton {
  float: right;
  margin-left: 10px;
}
.deleteButton:hover {
  cursor: pointer;
  color: red;
}

.generateButton {
  margin-top: 20px;
  margin-left: 10px;
  float: right;
}

.rightCol {
  justify-content: right;
  float: right;
}

.headerText {
  font-size: xx-large;
  margin-bottom: 50px;
  margin-top: 25px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.homeHeader {
  display: inline-block;
  margin-top: 5%;
}

.homeTitle {
  margin: auto;
  font-size: 2.4rem;
}
.homeIntro {
  margin: auto;
  font-size: 1.6rem;
}

